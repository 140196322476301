
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import SelectLanguage from "./SelectLanguage.vue";
import { RouterName } from "@/models/common";

export default defineComponent({
  name: "Footer",
  setup() {
    const { t } = useI18n({ useScope: "global" });
    return { t, RouterName };
  },
  components: { SelectLanguage },
});
