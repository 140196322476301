
import { computed, defineComponent } from "vue";
import { RouterName } from "@/models/common";
import router from "@/router";

export default defineComponent({
  name: "Main Nav",
  setup() {
    const activeRoute = computed(() => {
      return router.currentRoute.value.path;
    });

    const isActive = (item: string) => {
      if (item === "/") {
        if (activeRoute.value.toLowerCase().toString() === item) return true;
      } else
        return activeRoute.value
          .toLowerCase()
          .toString()
          .startsWith(item.toLowerCase());
    };
    return {
      RouterName,
      isActive,
      activeRoute,
    };
  },
});
