
import $API from "@/services";
import { defineComponent, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import { setLocale } from "@vee-validate/i18n";
import { usePrimeVue } from "primevue/config";

export default defineComponent({
  name: "Select Language",
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    const { t } = useI18n({ useScope: "global" });
    const state = reactive({
      locale: locale,
      t,
    });

    const primevue = usePrimeVue() as any;
    const changeLocale = (locale: string) => {
      setLocale(locale);

      primevue.config.locale.accept = t("accept");
      primevue.config.locale.reject = t("reject");
      primevue.config.locale.choose = t("FileChoose");
      primevue.config.locale.cancel = t("FileCancel");
      primevue.config.locale.upload = t("Fileupload");
      primevue.config.locale.emptyMessage = t("emptyMessage");
      primevue.config.locale.dayNamesShort = t("dayNamesShort")
        .valueOf()
        .split(",");
      primevue.config.locale.dayNamesMin = t("dayNamesMin")
        .valueOf()
        .split(",");
      primevue.config.locale.monthNames = t("monthNames").valueOf().split(",");
      primevue.config.locale.monthNamesShort = t("monthNamesShort")
        .valueOf()
        .split(",");
    };

    changeLocale(locale.value);

    const setLanguage = async () => {
      const language =
        locale.value === "en"
          ? "en-us"
          : locale.value === "ja"
          ? "ja-jp"
          : "ko-kr";
      const res = await $API.AccountsService.setLanguage(language);
      console.log(res);
    };

    watch(
      () => state.locale,
      (newLocale: string) => {
        changeLocale(newLocale);
        setLanguage();
        locale.value = newLocale;
      }
    );
    return { state };
  },
});
