
import { defineComponent, ref } from "vue";
import { RouterName } from "@/models/common";
import MainNavigation from "./MainNavigation.vue";
import router from "@/router";
import AuthNavigation from "@/components/common/AuthNavigation.vue";

export default defineComponent({
  name: "Header",
  setup() {
    const rootPath = ref(process.env.BASE_URL);
    const { name } = router.currentRoute.value.meta;
    const hamburger = ref(false);

    /**
     * mobile menu click event
     */
    const toggleHamburger = (bool?: Boolean) => {
      hamburger.value =
        bool === true || bool === false ? bool.valueOf() : !hamburger.value;
    };

    const goBack = () => {
      router.back();
    };

    return { RouterName, rootPath, name, hamburger, toggleHamburger, goBack };
  },
  components: { MainNavigation, AuthNavigation },
});
