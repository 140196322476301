
import { defineComponent, onUpdated, Ref, ref } from "vue";
import Footer from "../components/common/Footer.vue";
import Header from "../components/common/Header";

export default defineComponent({
  name: "Default Layout",
  setup() {
    const header$: Ref<Header | null> = ref(null);
    onUpdated(() => {
      header$.value?.toggleHamburger(false);
    });

    return { header$ };
  },
  components: { Footer, Header },
});
