
import { computed, defineComponent } from "vue";
import { RouterName } from "@/models/common";
import { AuthActionTypes } from "@/store/modules/auth/actions";
import { useStore } from "@/store";
import router from "@/router";

export default defineComponent({
  name: "Auth Nav",

  setup() {
    const store = useStore();
    const name = computed(() => store.getters.getAccountInfo?.Name);

    /**
     * logout link path
     */
    const logout = async () => {
      await store.dispatch(AuthActionTypes.SIGNOUT);
    };
    const activeRoute = computed(() => {
      return router.currentRoute.value.path;
    });

    const isActive = (item: string) => {
      if (item === "/") {
        if (activeRoute.value.toLowerCase().toString() === item) return true;
      } else
        return activeRoute.value
          .toLowerCase()
          .toString()
          .startsWith(item.toLowerCase());
    };

    return { RouterName, name, logout, isActive };
  },
  components: {},
});
